@import "primeng/resources/themes/lara-light-blue/theme.css"
@import "primeng/resources/primeng.css"
@import "primeicons/primeicons.css"

html
  font-size: 16px

//@media (min-width: 1920px) and (min-height: 1200px) and (min-resolution: 2dppx)
@media (min-width: 1920px) and (min-height: 1200px)
  html
    font-size: 18px
// Adjust font size for galaxy tablet only

.admin-title
  text-align: center

$rowSpace: 1rem
$colSpace: 1rem
$buttonColSpace: 0.5rem
$bp1199px: 75vw
$bp575px: 90vw
$header-height: 10vh
$footer-height: 5vh
$content-height: 100vh - $header-height - $footer-height

body
  font-family: 'Arial', sans-serif
  margin: 0
  padding: 0

@layer primeng

.p-datatable .p-datatable-tbody > tr > td
  padding: 0.5rem

.app-container
  display: flex
  flex-direction: column
  min-height: 100vh

.center-horizontal
  display: flex
  justify-content: center
  width: 100%

.bottom
  display: flex
  align-content: baseline
  height: 100%

.menu-item:not(:first-child)
  padding-left: 1rem

.logo
  height: 40px
  width: 40px

.app-header
  height: $header-height

.app-title
  padding-left: 2rem
  padding-right: 2rem
  font-size: xx-large
  font-weight: bold

.app-content
  flex: 1
  padding: 10px 20px 20px
  height: $content-height

.app-footer
  display: flex
  flex-direction: row
  justify-content: center
  align-content: center
  height: $footer-height
//max-height: 30px

.row-spacer
  margin-top: $rowSpace

.col-spacer
  margin-left: $colSpace

.button-col-spacer
  margin-left: $buttonColSpace

.row
  display: flex
  flex-direction: row

.col
  width: calc((100% - $colSpace) / 2)

.tab-col-checkbox
  width: 6ch
  text-align: center
  align-items: center

.tab-col-id
  width: 6ch

.tab-col-datetime
  width: 16ch
  // Dynamically accommodates approximately 12 characters
  white-space: nowrap

.tab-col-number
  text-align: right

.tab-col-actions1
  width: 10ch
  text-align: center

.tab-col-actions2
  width: 14ch
  white-space: nowrap
  text-align: center

.zip-input
  width: 6rem

.hcenter
  text-align: center

.custom-row-height .p-datatable-tbody > tr
  height: 40px

.checkbox-margin
  margin-right: 1rem

@mixin icon-style($color, $direction, $margin-size: 0.5rem)
  color: $color
  @if $direction == 'left'
    margin-right: $margin-size
  @else if $direction == 'right'
    margin-left: $margin-size

.warning-icon-left
  @include icon-style(#FFA726, 'left')

.warning-icon-right
  @include icon-style(#FFA726, 'right')

.info-icon-left
  @include icon-style(#2ba52b, 'left')

.info-icon-right
  @include icon-style(#2ba52b, 'right')
